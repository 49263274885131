module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"start_url":"/","lang":"el","name":"Καντεράκης Σπυρίδων","short_name":"Καντεράκης Σπυρίδων Ειδικός Κλινικός Καρδιολόγος","description":"Καντεράκης Σπυρίδων Ειδικός Κλινικός Καρδιολόγος","background_color":"#fff","theme_color":"#000","display":"minimal-ui","icon":"./src/images/fav.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c75376b97899ae53ba6c5741d02c27aa"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
